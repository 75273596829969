import React from 'react';

const CalibrationTooltipInformation = ({status, statusColor, error}) => {
    if (!status && !error) return <span>Not calibrated</span>;

    return (
        <div style={{color: statusColor}} className={"calibration_tooltip_result_container"}>
            {status && <p >Calibration status: <span>{status}</span></p>}
            {error && <p >Error: <span>{error}</span></p>}
        </div>
    )
};

export default CalibrationTooltipInformation;