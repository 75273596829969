import {useEffect} from 'react';
import {Route, Routes, useParams} from "react-router-dom";
import Second from "./Steps/Second";
import Third from "./Steps/Third";
import Finish from "./Steps/Finish";
import First from "./Steps/First";
import {useDispatch} from "react-redux";
import {changeEditCameraData} from "../../reduxToolkit/slices/Api/newGate";
import {PREVIEW, STEP_ONE, STEP_THREE, STEP_TWO} from "../../utils/RouteSettings/Paths";

const SwitchForm = ({defaultValues, submitFunc, onSendRequest}) => {
    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const oldDataCamera = JSON.parse(sessionStorage.getItem('camera'))

        if (id && oldDataCamera) dispatch(changeEditCameraData(oldDataCamera))
    }, [id]);

    return (
        <Routes>
            <Route path={'/'} element={<First/>}/>
            <Route path={STEP_ONE} element={<First/>}/>
            <Route path={STEP_TWO} element={<Second defaultValues={defaultValues} submitFunc={submitFunc}/>}/>
            <Route path={STEP_THREE} element={<Third defaultValues={defaultValues}/>}/>
            <Route path={PREVIEW} element={<Finish onSendRequest={onSendRequest}/>}/>
        </Routes>
    );
};

export default SwitchForm;