export const initializeStorageCameraProperties = (storageCamera) => {
    if (!storageCamera) return
    if (storageCamera?.position === "ramp") {
        if (!storageCamera?.calibration) storageCamera.calibration = {lines: [], landmarks: []}
        else {
            if (!storageCamera?.calibration.lines) storageCamera.calibration.lines = [];
            if (!storageCamera?.calibration.landmarks) storageCamera.calibration.landmarks = [];
        }

        if (!storageCamera?.clearance_points) storageCamera.clearance_points = [];
    }
    if (storageCamera?.position === "bridge" && !storageCamera.doors_point) storageCamera.doors_point = {};

    return storageCamera
}