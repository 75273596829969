import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../utils/axiosBody";
import {getAllGates} from "./GateRequest";
import {decoderToBytes} from "../../../utils/helpers/functions/decoders";

const camerasWithoutSomeFields = (cameras) => cameras.reduce((obj, {
    cctv_id,
    _id,
    top_left,
    bottom_right,
    ...rest
}) => {
    const crop = !top_left.length && !bottom_right.length
        ? []
        : `${top_left}, ${bottom_right}`.split(',').map(number => Number(number))
    obj[cctv_id] = {crop, ...rest}
    return obj
}, {})

export const sendDataGate = createAsyncThunk('formGate/createGate',
    async (data = {_id: '', cameras: []}) => {
        try {
            const {cameras, _id} = data
            return await air_axios_settings.post(
                '/gates/add',
                {_id, cameras: camerasWithoutSomeFields(cameras)}
            )
        } catch (error) {
            throw new Error(error)
        }
    })


export const sendCameras = createAsyncThunk('gates/sendCameras',
    async (endpoints = {cameras: [], _id: ''}) => {
        try {
            const {cameras, _id} = endpoints
            return await air_axios_settings.post(
                `/cameras/add`,
                camerasWithoutSomeFields(cameras), {params: {_id}}
            )
        } catch (error) {
            throw new Error(error)
        }

    })

export const getCameraRequest = createAsyncThunk('gates/getCamera',
    async (endpoint = {name: '', id: ''}) => {
        try {
            const {name: _id, id: cctv_id} = endpoint
            const {data} = await air_axios_settings.get(`/gates/cameras`, {params: {_id, cctv_id}});

            const {crop, cctv_params, ...rest} = data
            const parse_cctv_params =  JSON.stringify(cctv_params, null, '\t');

            if (crop && crop.length) {
                const [x1, y1, x2, y2] = crop
                rest.top_left = `${x1}, ${y1}`
                rest.bottom_right = `${x2}, ${y2}`
            }

            return {crop, cctv_params: parse_cctv_params, ...rest}
        } catch (error) {
            throw new Error(error)
        }

    })


export const editCameraById = createAsyncThunk('gates/editCamera',
    async (endpoint = {old: '', new: '', _id: '', camera: {}}) => {
        try {
            const {old, _id, camera} = endpoint
            const {data} = await air_axios_settings.put(
                `cameras/update`,
                camera,
                {params: {old, new: endpoint['new'], _id}}
            )
            return data
        } catch (error) {
            throw new Error(error)
        }
    })

export const deleteCameraById = createAsyncThunk('gates/removeCamera',
    async (params = {_id: '', cctv_id: ''}, {dispatch}) => {
        try {
            const {_id, cctv_id} = params
            const {data} = await air_axios_settings.delete("/cameras/remove", {params: {_id, cctv_id}})
            return [data, dispatch(getAllGates())]
        } catch (error) {
            throw new Error(error)
        }
    })


export const getCameraPosition = createAsyncThunk('gates/getPosition',
    async (camera) => {
        try {
            const {data} = await air_axios_settings.get(`/cameras/?cctv_id=${camera}&property=position`)
            return data
        } catch (error) {
            throw new Error(error)
        }
    })

export const sendCalibFrame = createAsyncThunk('cameras/sendCalibFrame',
    async (parameters = {cctv_id: '', file: {}}, {getState}) => {
    try {
        const {cctv_id, file} = parameters
        // const {file} = getState().ui.files
        const dataBytes = await decoderToBytes(file);
        return await air_axios_settings.post(
            "/cameras/upload_calibration_frame",
            dataBytes,
            {
                params: {cctv_id},
                headers: {"Content-Type": "application/octet-stream"},
                responseType: file.type
            })
    } catch (error) {
        throw new Error(error)
    }

})