import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    initialStateFormGate,
    removeCamara,
    toggleStorage,
    switchModalShowCamera
} from "../../../reduxToolkit/slices/Api/newGate";
import {useParams} from "react-router-dom";
import {CLOSE, TRASH} from "../../ANTD/Icons";
import classNames from "classnames";
import {Button} from "antd";
import {readStream} from "../../../reduxToolkit/asyncThunkFunctions/Api/ImageRequests";
import {onRemoveCamera} from "../../../reduxToolkit/slices/Api/Frames";
import {handleAbortRequest} from "../../../reduxToolkit/slices/Api/AbortController";
import {selectAbortController} from "../../../reduxToolkit/selectors/AbortController";
import {selectSwitchModal} from "../../../reduxToolkit/selectors/Api/formAddGate";
import SimpleRequestImgFallbackWrapper from "../../Customs/SimpleRequestImgFallbackWrapper";
import CustomCarousel from "../../Customs/CustomCarousel";

const Camera = ({cctv_id, view, position, mode, imgPath, url, isReadyToSend, imgLoading, imgError}) => {
    const dispatch = useDispatch();
    const {id} = useParams()
    const switchModal = useSelector(selectSwitchModal);
    const {abortController} = useSelector(selectAbortController)

    const handleRemoveCamera = () => {
        dispatch(removeCamara(cctv_id));
        dispatch(toggleStorage(true))
    }

    const handleCloseModalCamera = () => {
        dispatch(switchModalShowCamera(false))
        dispatch(initialStateFormGate())

        dispatch(onRemoveCamera(cctv_id))
        if (abortController) dispatch(handleAbortRequest())

    }

    const crossButton = () => switchModal ? handleCloseModalCamera() : handleRemoveCamera()
    const blockCameraClasses = classNames('wrapperCamera', {'hiddenCamera': switchModal})

    useEffect(() => {
        if (isReadyToSend) {
            dispatch(readStream({url, cctv_id, isReSendLastFrameHere: true}))
        }
    }, [isReadyToSend]);

    return (
        <main className={blockCameraClasses}>
            <section className={'sectionButton'}>
                {!id && <Button icon={switchModal ? CLOSE : TRASH} size={'large'} type={'default'} onClick={crossButton}/>}
            </section>
            <section className={'wrapperCamera__sectionCamera'}>
                <div className={'wrapperImages'}>
                    <div className={'imageBlock'}>
                        <SimpleRequestImgFallbackWrapper
                            loading={imgLoading}
                            error={imgError}
                            loadingClasses={"imageBlock__loading"}
                            errorClasses={"imageBlock__error"}
                            colorLoading={"blue"}
                        >
                            <CustomCarousel
                                data={imgPath}
                                renderItem={(item) => (
                                    <img
                                        src={item}
                                        alt={"img"}
                                        className={"smallImgCamera"}
                                        key={item}
                                    />
                                )}/>
                        </SimpleRequestImgFallbackWrapper>
                    </div>
                </div>
                <div className={'informationCamera'}>
                    <span>Id -> {cctv_id}</span>
                    <span>View -> {view}</span>
                    <span>Position -> {position}</span>
                    <span>mode -> {mode}</span>
                </div>
            </section>
        </main>
    )
};

export default Camera;
