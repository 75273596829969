import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toggleStorage} from "../../../reduxToolkit/slices/Api/newGate";
import {useNavigate, useParams} from "react-router-dom";
import PreviewImg from "../PreviewImg";
import {getUploadedFrame, readStream} from "../../../reduxToolkit/asyncThunkFunctions/Api/ImageRequests";
import {FORCE_FORM_GATE_STEP} from "../../../utils/RouteSettings/Links";
import {selectCctvFrames} from "../../../reduxToolkit/selectors/Api/frames";
import {selectIdCamera} from "../../../reduxToolkit/selectors/Api/formAddGate";
import PreviewBIgImg from "../StepThreeNew/PreviewBIgImg";
import ModalWrapper from "../../Customs/ModalWrapper";
import classNames from "classnames";
import {getStorageValue} from "../../../utils/CacheHelper";

const Third = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id, name} = useParams();
    const listOfImages = useSelector(selectCctvFrames);
    const id_camera = useSelector(selectIdCamera);


    const [showHideImg, setShowHideImg] = useState(false);

    const [isWhatDraw, setIsWhatDraw] = useState('');

    const modalClasses = classNames('modalWrapper', {
        'openImgModal': showHideImg, 'closeImgModal': !showHideImg
    });

    const cameraStorage = getStorageValue('camera', {}, 'session');
    const gateStorage = getStorageValue('gate', {}, 'session');

    const returnDrawPropsData = () => {
        const cameras = gateStorage?.cameras

        const findCamera = cameras?.find(({cctv_id}) => cctv_id === id_camera);
        const camera = id ? cameraStorage : findCamera;

        if (camera && camera.position === "bridge") return {markDoorPoint: camera?.doors_point}

        return {
            clearancePoints: camera?.clearance_points,
            calibration: camera?.calibration,
        }
    }

    const handleForceNavStep = (form_step) => {
        return navigate(FORCE_FORM_GATE_STEP({_id: name, cctv_id: id, form_step}))
    }
    const backToSetUpCameraInfo = () => handleForceNavStep('step_two');

    const handleNextStep = () => {
        if (!listOfImages[id_camera]?.imgError) dispatch(toggleStorage(true));
        handleForceNavStep('preview');
    }

    const handleClickHideImg = () => setShowHideImg(false);

    const handleClickShowImg = (value) => {
        setIsWhatDraw(value);
        setShowHideImg(true);
    }

    const frameRequests = ({url, cctv_id}) => {
        dispatch(readStream({url, cctv_id, isReSendLastFrameHere: true}));
        dispatch(getUploadedFrame(cctv_id))
    }

    useEffect(() => {
        const {url, cctv_id} = cameraStorage;
        if (cameraStorage && !listOfImages[id_camera]?.imgLink) frameRequests({url, cctv_id});
    }, [])

    return (
        <div style={{width: '600px'}}>
            <div className={'formGate imageSetting'}>
                <PreviewImg
                    handleClickShowImg={handleClickShowImg}
                    handleNextStep={handleNextStep}
                    backToSetUpCameraInfo={backToSetUpCameraInfo}
                />

                <ModalWrapper modalClasses={modalClasses}>
                    <PreviewBIgImg
                        hide={handleClickHideImg}
                        showHideImg={showHideImg}
                        isWhatDraw={isWhatDraw}
                        {...returnDrawPropsData()}
                    />
                </ModalWrapper>
            </div>
        </div>
    );
};

export default Third;