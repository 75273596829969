import React from 'react';
import Camera from "./Camera/Camera";
import {useSelector} from "react-redux";
import {selectCctvFrames, selectUploadFrames} from "../../reduxToolkit/selectors/Api/frames";


const PreviewGate = ({cameras, camera}) => {
    const listOfImages = useSelector(selectCctvFrames);

    const uploadedFrames = useSelector(selectUploadFrames); // test

    const cameraProps = (camera) => ({
        ...camera,
        imgPath: listOfImages[camera['cctv_id']]?.imgLink || uploadedFrames[camera["cctv_id"]]?.uploadedFrame,
        imgLoading: listOfImages[camera['cctv_id']]?.isImgLoading,
        imgError: listOfImages[camera['cctv_id']]?.imgError && !uploadedFrames[camera["cctv_id"]]?.uploadedFrame,
        isReadyToSend: !listOfImages[camera['cctv_id']]?.imgLink && !uploadedFrames[camera["cctv_id"]]?.uploadedFrame
    })

    if (Object.keys(camera).length > 0) {
        return <Camera {...cameraProps(camera)}/>
    }

    if (cameras.length > 0) {
        return cameras?.map((camera) => <Camera {...cameraProps(camera)} key={camera.cctv_id}/>)
    }

    return (
        <div className={'emptyBlock'}>
            <h1>Empty camera list</h1>
            <h2>You can't finish without camera</h2>
        </div>
    )
};

export default PreviewGate;