import {useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {yupCameraCheck} from "../../../utils/yupCheckes";
import {addCameraId, toggleStorage} from "../../../reduxToolkit/slices/Api/newGate";
import {ARROW_RIGHT} from "../../ANTD/Icons";
import {Button} from "antd";
import {getListOfCctvID} from "../../../reduxToolkit/asyncThunkFunctions/Api/GateRequest";
import {selectNewGate} from "../../../reduxToolkit/selectors/Api/formAddGate";
import {selectCctvIds} from "../../../reduxToolkit/selectors/Api/gates";
import {initializeStorageCameraProperties} from "../../../utils/helpers/functions/configuration";

const Second = ({defaultValues, submitFunc}) => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const {cctvIds} = useSelector(selectCctvIds);
    const {cameras} = useSelector(selectNewGate);
    const [allId, setAllId] = useState([]);
    const {
        register, reset,
        watch, handleSubmit,
        formState: {errors}
    } = useForm({
        defaultValues,
        resolver: yupResolver(yupCameraCheck(allId))

    });
    const camera = watch();

    const getAllCameraId = useMemo(() => {
        const uuid = watch("cctv_id");
        const array = [];

        cctvIds.forEach(cctv_id => {if (cctv_id !== uuid && cctv_id !== id) array.push(cctv_id + '')})

        cameras.forEach(({cctv_id}) => {if (cctv_id !== (uuid || id)) array.push(cctv_id)})

        return array
    }, [cctvIds, cameras]);

    const onSubmit = (data) => {
        submitFunc(initializeStorageCameraProperties(data));
        dispatch(addCameraId(data['cctv_id']));
        dispatch(toggleStorage(true));
        reset(defaultValues);
        sessionStorage.setItem('edit_setting', JSON.stringify({id_camera: data['cctv_id'], isEdit: false}));
    }

    useEffect(() => {
        setAllId(getAllCameraId);
    }, [cctvIds, cameras]);

    useEffect(() => {
        dispatch(getListOfCctvID());
    }, [])

    useEffect(() => {
        sessionStorage.setItem('camera', JSON.stringify(camera));
    }, [camera]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={'formGate'}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div className={'cameraFields'}
                     style={{width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className={'formGate__wrapperInput'}>
                        <label htmlFor="">cctv_id</label>
                        <input type="text" {...register('cctv_id')} required/>
                        {errors?.cctv_id && errors.cctv_id?.type &&
                            <span className={'errorInput'}>{errors.cctv_id['message']}</span>}
                    </div>
                    <div className={'formGate__wrapperInput'}>
                        <label htmlFor="">URL</label>
                        <input type="url" {...register('url')} placeholder={'http/https/rtsp'} required/>
                        {errors.url && errors.url?.type &&
                            <span className={'errorInput'}>{errors.url['message']}</span>}
                    </div>
                    <div className={'formGate__wrapperInput'}>
                        <label>View</label>
                        <select {...register('view')} required>
                            <option value="left-view">left-view</option>
                            <option value="right-view">right-view</option>
                            <option value="center-view">center-view</option>
                            <option value="under-the-belly">under-the-belly</option>
                        </select>
                    </div>
                    <div className={'formGate__wrapperInput'}>
                        <label>Position</label>
                        <select {...register('position')} required>
                            <option value="ramp">ramp</option>
                            <option value="bridge">bridge</option>
                            <option value="gate">gate</option>
                        </select>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <header style={{fontSize: '1.2rem', fontWeight: '600'}}><h4>Crop</h4></header>
                        <section style={{display: 'flex', justifyContent: 'center'}}>
                            <div className={'formGate__wrapperInput'}>
                                <label style={{fontSize: '0.9rem'}}>Top left</label>
                                <input type="text" {...register('top_left')} placeholder={'X1, Y1'}/>
                                {errors.top_left && errors.top_left?.type &&
                                    <span className={'errorInput'}>{errors.top_left['message']}</span>}
                            </div>
                            <div className={'formGate__wrapperInput'}>
                                <label style={{fontSize: '0.9rem'}}>Bottom right</label>
                                <input type="text" {...register('bottom_right')} placeholder={'X2, Y2'}/>
                                {errors.bottom_right && errors.bottom_right?.type &&
                                    <span className={'errorInput'}>{errors.bottom_right['message']}</span>}
                            </div>
                        </section>

                    </div>
                    <div className={'formGate__wrapperInput'}>
                        <label>Mode</label>
                        <div className={'inputsRadio'}>
                            <label className={'active'}>
                                <input type="radio" value={'active'} {...register('mode')} required/>
                                active
                            </label>
                            <label className={'notActive'}>
                                <input type="radio" value={'disabled'} {...register('mode')} required/>
                                disable
                            </label>
                        </div>
                    </div>
                </div>
                <div className={'cameraCctvParams'} style={{width: '600px', textAlign: 'center'}}>
                    <h4>Camera Calibration</h4>
                    <textarea{...register('cctv_params')}
                        defaultValue={"{}"}
                             style={{
                                 resize: 'none',
                                 height: '450px',
                                 width: '100%',
                                 borderRadius: '5px',
                                 padding: '5px'
                             }}
                             placeholder={'{"key" : value, "key" : value}'}
                    />
                    {errors.cctv_params && errors.cctv_params?.type &&
                        <span className={'errorInput'}>{errors.cctv_params['message']}</span>
                    }
                </div>
            </div>
            <Button type={'primary'} size={'large'} icon={ARROW_RIGHT} htmlType={'submit'}>Next</Button>
        </form>
    );
};

export default Second;