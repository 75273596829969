import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {defaultCameraState} from "../../utils/DefaultStates/States";
import {editCamera, initializeCamera} from "../../reduxToolkit/slices/Api/newGate";
import {getStorageValue} from "../../utils/CacheHelper";
import {useNavigate, useParams} from "react-router-dom";
import SwitchForm from "./SwitchForm";

import {FORCE_FORM_GATE_STEP} from "../../utils/RouteSettings/Links";
import {selectNewGate} from "../../reduxToolkit/selectors/Api/formAddGate";

const FormGate = ({onSendRequest}) => {
    const navigate = useNavigate()
    const {name, id} = useParams()
    const dispatch = useDispatch()
    const {_id} = useSelector(selectNewGate);

    const defaultValues = getStorageValue('camera', defaultCameraState);

    const toggleFunc = (value) => {
        const {cctv_params, ...rest} = value
        const edit_setting = JSON.parse(sessionStorage.getItem('edit_setting'));

        const newCamera = {_id, cctv_params: JSON.parse(cctv_params), ...rest}

        if (edit_setting && edit_setting.isEdit) {
            dispatch(editCamera({id: edit_setting['id_camera'] , editObj: newCamera}))
        }

        dispatch(initializeCamera(newCamera))
    }

    const handleSubmitCamera = (data) => {
        toggleFunc(data)
        navigate(FORCE_FORM_GATE_STEP({_id: name, cctv_id: id, form_step: 'step_three'}))
    }

    return (
        <div className={'wrapperFormAndPreview'}>
            <SwitchForm defaultValues={defaultValues} submitFunc={handleSubmitCamera} onSendRequest={onSendRequest}/>
        </div>
    );
};

export default FormGate;