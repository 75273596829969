import {useCallback, useMemo, useRef} from 'react';
import {landmarks as landmarkColumns} from "../../utils/Table/Columns/landmarks";
import ExtrinsicCrosses from "../Canvas/DrawingWindows/ExtrinsicCrosses";
import {Table} from "antd";
import DrawButtons from "../FormAddGate/StepThreeNew/DrawButtons";
import {CIRCLE_CLOSE, CLOSE, INFO, SAVE, UPLOAD} from "../ANTD/Icons";
import {showModalInfoContent} from "../../utils/helpers/functions/modalContent";
import {extrinsicDrawButtonsTools} from "../../utils/helpers/objects/drawButtonsTools";
import {isEqual} from "../../utils/helpers/functions/analiza";
import classNames from "classnames";
import useThrottledResizeObserver from "../../hooks/useThrottledResizeObserver";
import useImg from "../../hooks/useImg";
import {useDispatch, useSelector} from "react-redux";
import {selectCctvFrames, selectUploadFrames} from "../../reduxToolkit/selectors/Api/frames";
import {selectIdCamera} from "../../reduxToolkit/selectors/Api/formAddGate";
import {scrollByY} from "../../utils/Table/Scroll";
import {HEADER_TABLE_HEIGHT} from "../../utils/Sizes";
import {updateFieldToGateCameraForm} from "../../reduxToolkit/slices/Api/newGate";
import useReactZoomPanPinch from "../../hooks/useReactZoomPanPinch";
import {toast} from "react-toastify";
import {
    BEGINNER_MODES,
    FINAL_MODES,
    MAIN_MODES,
    UN_BASIC_MODES
} from "../../utils/helpers/arrays/extrinsicModes";
import useUploadFrameHandler from "../../hooks/useUploadFrameHandler";
import {imageFileAccepts} from "../../utils/helpers/variables/accepts";
import ImageFallbackWrapper from "../Customs/ImageFallbackWrapper";

const DRAW_BUTTONS_WRAPPER_MARGIN_TOP_BOTTOM = 3 // just save here on some time
const MODAL_TOP_BOTTOM_PADDING = 6

const CompositeLandmarksDrawAndTable = ({
         landmarks, setLandmarks,
         prevElem, setPrevElem,
         mode, setMode, onCloseModal,
         calibrationStorage, color, handleOnChangeLandmarkColorPicker
    }) => {
    const dispatch = useDispatch();
    const frames = useSelector(selectCctvFrames);
    const cctv_id = useSelector(selectIdCamera);

    const uploadedFrames = useSelector(selectUploadFrames);
    const liveImg = frames[cctv_id]?.imgLink || uploadedFrames[cctv_id]?.uploadedFrame

    const {img_width, img_height} = useImg(liveImg);

    const {ref: ref_canvas_frame_block, width, height, dpi_width, dpi_height} = useThrottledResizeObserver(100);
    const [scale, setScale] = useReactZoomPanPinch(1);

    const onUploadImgFile = useUploadFrameHandler(cctv_id);

    const isBasicMode = UN_BASIC_MODES.includes(mode);
    const isFinalMode = FINAL_MODES.includes(mode);
    const isBeginnerMode = BEGINNER_MODES.includes(mode);
    const isMainMode = MAIN_MODES.includes(mode);

    const drawButtonsWrapperRef = useRef(null);

    const drawingResizeWrapperClasses = classNames(
        "landmark-drawing-wrapper__drawing-resize-wrapper", {
            "disabled-modal-block": !mode || mode === "editRight"
        })

    const addBtnClasses = classNames("add_draw_btn", {
        "final_btn": mode === "finish", "cancelled_btn": isBeginnerMode
    });

    const onClearPrevElemState = () => {
        if (prevElem.index && prevElem.prev) setPrevElem({index: null, prev: null});
    }

    const onClearMode = () => {if (mode) setMode(null)}

    const onClearLandmarksList = () => {if (landmarks.length) setLandmarks([]);}

    const handleAddAndUpdateCross = ({clientX, clientY}, setCorrectHandlePosition) => {
        if (mode && mode !== "editRight") {
            setLandmarks(prevList => {
                const copyPrevList = [...prevList];
                const currentIndex = prevElem.index ?? copyPrevList.length - 1;
                const {x: mouseX, y: mouseY} = setCorrectHandlePosition(clientX, clientY);
                const correctCross  = {x: mouseX / (width * scale), y: mouseY / (height * scale)};

                if (mode === "start") {
                    copyPrevList.push({...correctCross, color});
                    setMode("preparing");
                }

                if (mode !== "start") {
                    if (mode === "preparing" && copyPrevList[currentIndex]?.lat && copyPrevList[currentIndex]?.lng) {
                        setMode("finish");
                    }
                    copyPrevList[currentIndex] = {...copyPrevList[currentIndex], ...correctCross}
                }
                return copyPrevList
            });
        }
    };

    function handleFinishMode () {
        return setMode(null);
    }

    const handleFilteredUnequalItems = ({lat: clickedLat, lng: clickedLng, x: clickedX, y: clickedY}) => {
        setLandmarks(prevList => {
            return prevList.filter(({lat, lng, x, y}) => {
                return clickedLat !== lat && clickedLng !== lng && x !== clickedX && y !== clickedY;
            })
        })
        onClearPrevElemState()
        onClearMode()
    }

    const handleClearList = () => {
        onClearMode()
        onClearLandmarksList()
        onClearPrevElemState()

    }

    const onReturnToPrevPos = () => {
        setLandmarks(prevList => {
            const copyPrevList = [...prevList]
            const {index, prev} = prevElem
            copyPrevList[index] = {...copyPrevList[index], ...prev}
            return copyPrevList
        });
    };

    const handleRefuseElem = () => {
        onReturnToPrevPos()
        handleFinishMode();
        onClearPrevElemState()
    }

    const handleMarkedElem = (markedElem, editSide) => {
        if (prevElem.prev && !isEqual(markedElem.prev, prevElem.prev)) onReturnToPrevPos()
        setPrevElem(markedElem);
        setMode(editSide);
    }

    const handleStartAddPair = () => setMode("start");

    const handleStopAddPair = () => {
        if (landmarks.length >= 0) {
            handleFinishMode();
            setLandmarks(prev => prev.filter(landmark => Object.keys(landmark).length === 5));
        }
    }

    const handleApproveChanges = () => {
        handleFinishMode()
        setPrevElem({index: null, current: null, prev: null});
    }

    const handleSaveLandmarks = () => {
        const isMainMode = MAIN_MODES.includes(mode)
        if (isMainMode) return toast.warning("Finish action please");

        if (!isMainMode) {
            dispatch(updateFieldToGateCameraForm({
                key: "calibration",
                value: {
                    ...calibrationStorage,
                    landmarks: landmarks.map(({color, ...rest}) => rest),
                    status: "changed"
                }
            }));
            handleClearList()
            onCloseModal()
        }
    }

    const handleCloseLandmark = () => {
        onCloseModal()
        handleClearList()
    }

    const addPairButtonTools = useMemo(() => {
        if (isBeginnerMode) return {name: "Cancel", onClick: handleStopAddPair, icon: CLOSE}
        if (mode === "finish") return {name: "Save Pair", onClick: handleFinishMode, icon: SAVE}
        return {name: "Add Pair", onClick: handleStartAddPair}
    }, [mode]);

    const busySpaceForScroll = useMemo(() => {
        const drawButtonsHeight = drawButtonsWrapperRef?.current?.clientHeight;
        const res = height
            + HEADER_TABLE_HEIGHT
            + drawButtonsHeight
            + (DRAW_BUTTONS_WRAPPER_MARGIN_TOP_BOTTOM * 2)
            + MODAL_TOP_BOTTOM_PADDING
        return scrollByY(res);
    }, [height, drawButtonsWrapperRef]);

    const injectedMousePosToLandmarks = useMemo(() => {
        return landmarks.map(landmark => {
            if (!landmark.mouseX && !landmark.mouseY) {
                return {
                    ...landmark,
                    mouseX: Math.floor(landmark.x * img_width),
                    mouseY: Math.floor(landmark.y * img_height)
                }
            }
            return landmark
        })
    }, [landmarks, img_width, img_height]);

    const disabledActionTrashBtn = useCallback((index) => {
        const isInPreparing = index === landmarks.length - 1 && isFinalMode;
        return isInPreparing || isBasicMode
    }, [mode, landmarks])

    const extrinsicCalibColumn = landmarkColumns({
        prevElem,
        handleMarkedElem,
        handleRefuseElem,
        handleApproveChanges,
        handleFilteredUnequalItems,
        isDisableBtn: !isBasicMode,
        isDisableActionTrashBtn: disabledActionTrashBtn
    });

    const drawBtnProps = {
        isChanged: !isEqual(landmarks.map(({color, ...rest}) => rest), calibrationStorage?.landmarks),
        add: {...addPairButtonTools, disabled: prevElem.prev, classes: addBtnClasses},
        reset: {...extrinsicDrawButtonsTools.reset, onConfirm: handleClearList, disabled: !landmarks.length},
        close: {...extrinsicDrawButtonsTools.close, onClick: handleCloseLandmark},
        save: {
            ...extrinsicDrawButtonsTools.save, icon: CIRCLE_CLOSE,
            onConfirm: handleSaveLandmarks, onCancel: handleCloseLandmark
        },
        info: {
            icon: INFO, onClick: () => showModalInfoContent({
                title: 'Draw instructions', width: '500px', infoContent: {}
            })
        },
        upload: {onChange: onUploadImgFile, icon: UPLOAD, accept: imageFileAccepts, disabled: isMainMode},
        colorPicker: {value: color, onChangeComplete: handleOnChangeLandmarkColorPicker}
        // need to add disable on upload when you are trying to add point or edit some one
    }

    const LandmarkCrossProps = {
        handleAddAndUpdateCross,
        landmarks,
        prevElem,
        dpi_height,
        dpi_width,
        setScale,
    }

    return (
        <div className={"landmarks_wrapper__left-side"}>
            <>
            <div ref={ref_canvas_frame_block} className={drawingResizeWrapperClasses}>
                <ImageFallbackWrapper
                    className={"landmark-drawing-wrapper"}
                    img={liveImg}
                    errorClassName={"landmark-drawing-error-wrapper"}
                >

                    <ExtrinsicCrosses {...LandmarkCrossProps} />

            </ImageFallbackWrapper>
            </div>
            <div
                ref={drawButtonsWrapperRef} className={"big_img_wrapper__draw_btn_section"}
                    style={{margin: `${DRAW_BUTTONS_WRAPPER_MARGIN_TOP_BOTTOM}px 0`}}
                >
                    <DrawButtons {...drawBtnProps} />

                </div>
            </>

            <Table
                dataSource={injectedMousePosToLandmarks}
                columns={extrinsicCalibColumn}
                rowKey={({lat, lng, x, y}) => lat && lng ? `${lat}-${lng}` : `${x}-${y}`}
                pagination={false}
                className={"ant-landmarks-left-side-table-wrapper"}
                scroll={{y: busySpaceForScroll}}
                tableLayout={"auto"}
            />
        </div>
    )
}

export default CompositeLandmarksDrawAndTable;