import React from 'react';
import Intrinsic from "../../Canvas/DrawingWindows/Intrinsic";
import ClearanceZone from "../../Canvas/DrawingWindows/ClearanceZone";
import MarkDoor from "../../Canvas/DrawingWindows/MarkDoor";
import Extrinsic from "../../DrawOnMaps/Extrinsic";

const PreviewBIgImg =
    ({
         isWhatDraw,
         clearancePoints,
         markDoorPoint,
         ...restProps
    }) => {
    switch (isWhatDraw) {
        case 'clearance zone':
            return <ClearanceZone clearancePointsStorage={clearancePoints} {...restProps}/>
        case 'intrinsic':
            return <Intrinsic {...restProps} />
        case 'extrinsic':
            return <Extrinsic {...restProps}/>
        case 'mark door':
            return <MarkDoor markDoorPointStorage={markDoorPoint} {...restProps}/>
        default:
            return null
    }
};

export default PreviewBIgImg;