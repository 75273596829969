import {useCallback, useEffect} from 'react';
import DrawButtons from "../../FormAddGate/StepThreeNew/DrawButtons";
import {clearanceZoneTooltipInst} from "../../../utils/helpers/objects/DrawDescription";
import {returnXY} from "../../../utils/helpers/functions/calcPositions";
import {toggleStorage, updateFieldToGateCameraForm} from "../../../reduxToolkit/slices/Api/newGate";
import {useDispatch, useSelector} from "react-redux";
import useImg from "../../../hooks/useImg";
import useThrottledResizeObserver from "../../../hooks/useThrottledResizeObserver";
import useReadyState from "../../../hooks/useReadyState";
import {selectCctvFrames, selectUploadFrames} from "../../../reduxToolkit/selectors/Api/frames";
import {selectIdCamera} from "../../../reduxToolkit/selectors/Api/formAddGate";
import {showModalInfoContent} from "../../../utils/helpers/functions/modalContent";
import {isEqual} from "../../../utils/helpers/functions/analiza";
import {circleElem, clearCanvasList} from "../../../utils/helpers/functions/drawCanvasItems";
import {extrinsicDrawButtonsTools} from "../../../utils/helpers/objects/drawButtonsTools";
import {CIRCLE_CLOSE, INFO, UNDO, UPLOAD} from "../../ANTD/Icons";
import useUploadFrameHandler from "../../../hooks/useUploadFrameHandler";
import {imageFileAccepts} from "../../../utils/helpers/variables/accepts";
import {Carousel} from "antd";
import ZoomableCanvasFrameComponent from "../../Customs/ZoomableCanvasFrameComponent";
import ImageFallbackWrapper from "../../Customs/ImageFallbackWrapper";

const ClearanceZone = ({hide, showHideImg, clearancePointsStorage}) => {
    const dispatch = useDispatch();

    const frames = useSelector(selectCctvFrames);
    const cctv_id = useSelector(selectIdCamera);
    const uploadedFrames = useSelector(selectUploadFrames);

    const liveImg = frames[cctv_id]?.imgLink || uploadedFrames[cctv_id]?.uploadedFrame
    const {img_height, img_width} = useImg(liveImg);
    const {ref: ref_canvas_frame_block, width, height, dpi_width, dpi_height} = useThrottledResizeObserver(100);
    const {state: clearancePoints, onChangeReadyState: setClearancePoints} = useReadyState([]);
    const {state: zoomScale, onChangeReadyState: setNewScale} = useReadyState(1);
    const {state: carouselSlideNumber, onChangeReadyState: setCarouselSlideNumber} = useReadyState(null);
    const onUploadImgFile = useUploadFrameHandler(cctv_id);

    const frameList = [frames[cctv_id].imgLink, uploadedFrames[cctv_id]?.uploadedFrame];

    console.log('ClearanceZone')

    const handleSavePoints = () => {
        dispatch(updateFieldToGateCameraForm({key: "clearance_points", value: clearancePoints}))
        dispatch(toggleStorage(true))
        hide()
    }
    const handleGetMousePoss = ({clientX, clientY}, setCorrectHandlePosition) => {
        const {x, y} = setCorrectHandlePosition(clientX, clientY);
        setClearancePoints([...clearancePoints, [x / (width * zoomScale), y / (height * zoomScale)]]);
    }

    const trackingZoomScale = (event) => {
        const scale = event.state.scale
        if (scale < 1) setNewScale(1);
        else setNewScale(scale);
    }

    const handleUndo = () => {
        const copyClearancePoints = [...clearancePoints];
        copyClearancePoints.pop();
        setClearancePoints(copyClearancePoints)
    }

    const handleClear = () => {
        setClearancePoints([]);
    }

    const handleCloseModal = () => {
        handleClear()
        hide()
    }

    const printCircles = (context) => clearancePoints.forEach(([startLine, endLine]) => {
        const {x, y} = returnXY(startLine, endLine, dpi_width, dpi_height)
        circleElem(context, x , y, 7, 6, 'red');
    })

    const printLines = (context) => {
        context.beginPath();
        context.lineWidth = 5;
        context.strokeStyle = 'blue';
        clearancePoints[0] && context.moveTo(clearancePoints[0][0] * dpi_width, clearancePoints[0][1] * dpi_height);

        clearancePoints.forEach(([x, y], index) => {
            if (index > 0) context.lineTo(x * dpi_width, y * dpi_height)
        })
        context.stroke();
        context.closePath()
    }

    const drawClearanceZoneLines = useCallback((context) => {
        clearCanvasList(context)
        printCircles(context)
        printLines(context)
    }, [clearancePoints, dpi_width, dpi_height])

    const zoomableImgWrapperProps = {
        dpi_width,
        dpi_height,
        handleGetMousePosition: handleGetMousePoss,
        trackingZoomScale,
        drawCanvasFunction: drawClearanceZoneLines
    }

    const drawBtnProps = {
        isChanged: !isEqual(clearancePoints, clearancePointsStorage),
        save: {...extrinsicDrawButtonsTools.save, icon: CIRCLE_CLOSE, onConfirm: handleSavePoints, onCancel: handleCloseModal},
        remove: {name: "UNDO", onClick: handleUndo, disabled: !clearancePoints.length, icon: UNDO},
        reset: {...extrinsicDrawButtonsTools.reset, onConfirm: handleClear, disabled: !clearancePoints.length},
        close: {...extrinsicDrawButtonsTools.close, onClick: handleCloseModal},
        info: {
            icon: INFO,
            onClick: () => showModalInfoContent({
                title: "Drawing instructions", width: '550px', infoContent: clearanceZoneTooltipInst
            })
        },
        upload: {onChange: onUploadImgFile, icon: UPLOAD, accept: imageFileAccepts}
    }

    const imgSectionStyles = {maxWidth: `${img_width}px`, maxHeight: `${img_height}px`}

    const imgFallbackProps = {
        className: "fallback_img_wrapper",
        errorClassName: "fallback_error_wrapper",
        loadingClassName: "fallback_loading_wrapper",
        img: liveImg
    }

    useEffect(() => {
        if (showHideImg && clearancePointsStorage && !clearancePoints.length) { // save for the late
            setClearancePoints(clearancePointsStorage)
        }
    }, [showHideImg])

    return (
        <div className={'big_img_wrapper'}>
            <div className={'big_img_wrapper__img_section'} style={imgSectionStyles}>
                <ImageFallbackWrapper {...imgFallbackProps}>
                    <div ref={ref_canvas_frame_block} className={'zoomable_img_wrapper'}>
                        <Carousel adaptiveHeight dots={frameList.every(frame => frame)}
                                  afterChange={setCarouselSlideNumber}>
                            {frameList.map((frame, index) => {
                                if (frame) {
                                    return (
                                        <ZoomableCanvasFrameComponent
                                            {...zoomableImgWrapperProps}
                                            url={frame}
                                            key={frame}
                                            isReadyZoomToDef={carouselSlideNumber !== null && carouselSlideNumber !== index}
                                        />
                                    )
                                }
                            })}
                        </Carousel>
                    </div>
                </ImageFallbackWrapper>

            </div>
            <footer className={"big_img_wrapper__draw_btn_section positioned_draw_btn_section"}>
                <DrawButtons {...drawBtnProps} />
            </footer>
        </div>
    );
};

export default ClearanceZone;