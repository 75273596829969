import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    file: null,
    files: {},
}


const filesSlice = createSlice({
    name: "files",
    initialState,
    reducers: {
        setFile(state, {payload}) {
            state.file = payload
        },
        setFiles(state, {payload: {fileKey, fileData}}) {
            state.files[fileKey] = fileData
        },
        setInitialFileState: () => initialState
    }
})



export const {actions, reducer} = filesSlice

export const {setFile, setFiles, setInitialFileState } = actions
export default reducer