export const clearanceZoneTooltipInst = {
    mainTitleText: "The clearance zone denotes the ramp area around the aircraft, where the aircraft servicing occurs.  It is usually marked with a red line on the ground.\n" +
        " Any on/off ramp events generated by the system will use the location of the clearance zone. \n",

    toolsTitleText: "Please trace the clearance zone with the provided tools.",

    toolsList: [
        {id: 1, text: "Double-click the image to create a point.",},
        {id: 2, text: "Each consecutive double click, will place a new point on the image, with a line connecting it to the previous point.",},
        {id: 3, text: "The last two points will be connected automatically to close a polygon."}
    ],

    buttonsToolsList: [
        {name: 'Undo', description: "Undo the last point you placed."},
        {name: 'Clear', description: "Clears all points."},
        {name: 'Close', description: "Closes the clearance zone view."}
    ]
};

export const calibrationTooltipInst = {
    mainTitleText: "Camera calibration allows the system to measure distances and sizes in the image and reverse lens distortion (Fisheye). Lens distortion causes parallel lines to look curved in images.\n" +
        "The calibration procedure is automatic and happens in the background, however, it requires your help to find straight lines in the image.",

    toolsTitleText: "Please use the tools below to mark at least 4 lines that you know to be straight in the real world, but may look curved in the image due to lens distortion.",

    toolsList: [
        {id: 1, text: 'Click on "draw new line" button.',},
        {id: 2, text: 'Double-click the image to create a point.'},
        {id: 3, text: 'Each consecutive double click, will place a new point on the image, with a line connecting it to the previous point. Draw a minimum of 4 points to trace a line that you know is straight in the real world, although it may look curved in an image. This can be aircraft parking line, boundaries of the clearance zone, the edge of concrete labs, and',
            additions: ['Take care to be as accurate as possible. Use your mouse scroll function to zoom into the image.', "It's best to pick clear edges and corners to mark due to their contrast."]
        },
        {id: 4, text: 'Once you are done with the line, click "finish line"'},
        {id: 5, text: 'The current line where you stopped is always marked with the color "AQUA"', color: '#00FFFF'}
    ],

    buttonsToolsList: [
        {name: 'Draw new line', description: 'Start drawing a new line'},
        {name: 'Finish Drawing', description: 'Finish drawing the current line.'},
        {name: 'Undo', description: "Undo the last point you placed."},
        {name: 'Clear', description: "Clears all lines."},
        {name: 'Close', description: "Closes the calibration view."}
    ]
}