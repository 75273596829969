import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {changeEditCameraData, switchModalShowCamera} from "../../reduxToolkit/slices/Api/newGate";
import {useDispatch} from "react-redux";
import {
    handleOnChangeIsUpdateDataStatus,
    removeCamera
} from "../../reduxToolkit/slices/Api/sliceGateRequest";
import {ConfirmTitles, TooltipTitles} from "../../utils/Table/Titles";
import {
    deleteCameraById,
    editCameraById,
    getCameraRequest
} from "../../reduxToolkit/asyncThunkFunctions/Api/CamerasRequests";
import {CLOSE, MARK, PENCIL, RegCAMERA, STOP, TRASH} from "../ANTD/Icons";
import classNames from "classnames";
import {Button, Popconfirm, Switch, Tooltip} from "antd";
import {FORM_GATE_EDIT_CAMERA_LINK} from "../../utils/RouteSettings/Links";

import {ReactComponent as CalibrationResultIcon} from "../../images/svg/target-30.svg"
import CalibrationTooltipInformation from "./CalibrationTooltipInformation";
import {calibrationTooltipColors} from "../../utils/helpers/objects/configuration";
import {extraCalibrationColorStatues} from "../../utils/helpers/arrays/configuration";

const CameraButtons = ({camera: {cctv_id, mode, calibration: {status, error}, position}, _id, camera}) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()

    const [cameraMode, setCameraMode] = useState(false);

    const btnToggleClasses = classNames('btn-toggle', {'modified': cameraMode, 'unaltered': !cameraMode});

    const calibrationResultClasses = classNames("camera-icon-result-container__calibration-result", {
        "excellent": status === "excellent",
        "good": status === "good",
        "bad": status === "bad",
        "animated": status === "validating" || status === "calibrating",
        "failed": status === "failed",
        "bridge": position === "bridge"
    });

    const onChangeCameraMode = (camera_mode) => setCameraMode(camera_mode)

    const handleEditCamera = async () => {
        await dispatch(getCameraRequest({name: _id, id: cctv_id}))
        navigation(FORM_GATE_EDIT_CAMERA_LINK(_id, cctv_id, 'step_two'))
    }

    const handleRemoveCamera = async () => {
        const params = {_id, cctv_id}
        await dispatch(deleteCameraById(params))
        await dispatch(removeCamera(params))
        await dispatch(handleOnChangeIsUpdateDataStatus(true))
    }

    const showCameraInfo = async () => {
        await dispatch(changeEditCameraData(camera))
        await dispatch(switchModalShowCamera(true))
    }

    const handleChangeMode = async (value) => {
        const mode = value ? 'active' : 'disabled'
        const arg = {cctv_id, _id, mode} // old
        const argUpdateCamera = {old: arg['cctv_id'], new: arg['cctv_id'], _id, camera: {mode, cctv_id}} // new
        await setCameraMode(value)
        await dispatch(editCameraById(argUpdateCamera))
        await dispatch(handleOnChangeIsUpdateDataStatus(true))
    }

    useEffect(() => {
        if (mode === 'active') {
            onChangeCameraMode(true)
        } else {
            onChangeCameraMode(false)
        }
    }, [mode, camera]);

    return (
        <div className={'configuration-camera'}>
            <header className={'configuration-camera__camera-title'}>

                <div className={"camera-icon-result-container"}>
                    <Tooltip placement="bottom" title={TooltipTitles.previewCameraInform}>
                        <Button icon={RegCAMERA} type={'primary'} className={'camera-icon-result-container__camera-icon'} onClick={showCameraInfo}/>
                    </Tooltip>
                    <div className={calibrationResultClasses}>
                        <Tooltip
                            placement={"topLeft"}
                            title={<CalibrationTooltipInformation
                                error={error}
                                status={status}
                                statusColor={extraCalibrationColorStatues.includes(status) ? "#ffffff" : "#000000"}
                            />}
                            color={calibrationTooltipColors[status]}
                        >
                            <CalibrationResultIcon/>
                            {status === "failed" && <span className={"failed-icon-result"}>{STOP}</span>}
                        </Tooltip>
                    </div>
                </div>
                <span className={'camera-number'}>{cctv_id}</span>
            </header>

            <section className={'configuration-camera__btn-camera-container'}>
                <Popconfirm title={ConfirmTitles.edit} onConfirm={handleEditCamera}>
                    <Tooltip placement="bottomRight" title={TooltipTitles.editCamera}>
                        <Button icon={PENCIL} type={'primary'} className={'btn-camera'}/>
                    </Tooltip>
                </Popconfirm>

                <Popconfirm title={ConfirmTitles.remove} onConfirm={handleRemoveCamera}>
                    <Tooltip placement="bottom" title={TooltipTitles.removeCamera}>
                        <Button icon={TRASH} type={'primary'} className={'btn-camera'}/>
                    </Tooltip>
                </Popconfirm>

                <Tooltip placement="bottomLeft" title={cameraMode ? TooltipTitles.activeCamera : TooltipTitles.disableCamera}>
                    <Switch checkedChildren={MARK} unCheckedChildren={CLOSE}
                            checked={cameraMode} onChange={handleChangeMode} className={btnToggleClasses}
                    />
                </Tooltip>

            </section>
        </div>
    );
};

export default CameraButtons;